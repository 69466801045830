/* Overides for "react-big-calendar" */

.rbc-agenda-view,
.rbc-time-view,
.rbc-month-view {
  margin-bottom: 10rem;
  border-radius: 0.2rem;
  border-collapse: collapse;
}

.rbc-month-row {
  flex-basis: 300px;
}

.rbc-header {
  padding: 0;
  border-bottom: 0;
}

.rbc-time-content {
  border-top: 0;
}

.rbc-row-segment {
  padding: 0 1px 5px 1px;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  text-transform: none;
}

.rbc-day-slot .rbc-event-label {
  margin-left: 5px;
  margin-top: 5px;
}

.rbc-toolbar-label,
.rbc-header,
.rbc-overlay-header,
.rbc-agenda-date-cell {
  text-transform: capitalize;
  font-weight: bolder;
}

.rbc-allday-cell {
  display: none;
}

.rbc-day-slot .rbc-event {
  border-color: black;
  color: black;
  background: white;
}

.rbc-event {
  padding: 0;
  background: transparent;
}

.rbc-btn-group button {
  color: rgba(0, 0, 0, 0.56);
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  line-height: 1.75;
  font-weight: 500;
  font-size: 0.8125rem;
  border: 1px solid #0000001f;
}

.rbc-toolbar button.rbc-active,
.rbc-toolbar button.rbc-active:focus,
.rbc-toolbar button.rbc-active:active,
.rbc-toolbar button.rbc-active:hover {
  box-shadow: none;
  border-color: #adadad;
  color: #ffffff;
  background-color: #fe5c61;
}

.rbc-show-more {
  color: black;
}

.rbc-agenda-event-cell {
  padding: 0;
}

.rbc-event.rbc-selected {
  background-color: #c6c6c6;
}

.rbc-day-slot .rbc-event {
  border-color: #c7c7c7;
  color: black;
  border-radius: 0.3rem;
  background: white;
}
